import { defineComponent } from 'vue'
import UserEvent from '../objects/UserEvent'

export default defineComponent({
    name: 'WithPageViewTracking',
    async mounted() {
        await this.trackPageView()
    },
    methods: {
        async trackPageView() {
            await this.$logTrackingEvent(UserEvent.PAGE_VIEW, window.location.href)
        },
    },
})
